declare var window: any;

/**
 * 获取对象的指定key值
 * eg:
 *  var obj = {
 *      a:1,
 *      b:2,
 *      c:{
 *          d:3,
 *          e:[
 *              1,2,3
 *          ],
 *          f:[
 *              {
 *                  1:2
 *              }
 *          ]
 *      }
 *  };
 *
 *  console.log(
 *      getVal(obj, 'a'),
 *      getVal(obj, 'c.d'),
 *      getVal(obj, 'c.e.1'),
 *      getVal(obj, 'c.f.0.1'),
 *      getVal(obj, 'c.f.f', 'haha'),
 *      obj
 *  )
 * @param {Object|Array} target
 * @param {string} query
 * @param {any} defaultValue
 */
export function getVal(obj: any, query: string, defaultValue?: any): any {
	if (obj === null || !(typeof obj === 'object')) {
		let realType: any = obj === null ? 'null' : typeof obj;
		console.error(`[getVal]: parameter obj's type should be 'object', but it is ${realType}`);

		return defaultValue;
	}
	if (typeof query !== 'string') {
		throw new Error(`[getVal]: parameter query's should be string, but it is ${typeof query}`);
	}
	const keys: any = query.split('.');
	let valObj: any = obj;
	for (let i: number = 0, len: number = keys.length; valObj && i < len; i++) {
		valObj = valObj[keys[i]];
	}
	if (!valObj) {
		return defaultValue;
	} else {
		return valObj;
	}
}

/**
 * 页面url跳转，延时150毫秒
 * @param  {[type]} url [description]
 * @return {[type]}     [description]
 */
export function locationTo(url: string, weappUrl?: string, isNavigate?: any): any {
	setTimeout((): any => {
		const search: any = location.search.slice(1);
		const channelParam: any = search.split('&').filter((param: any): boolean => {
			return param.indexOf('ch=') === 0;
		})[0];
		const channel: any = channelParam ? channelParam.split('=')[1] : '';
		if (channel) {
			if (url.indexOf('?') >= 0) {
				// tslint:disable-next-line:no-parameter-reassignment
				url = `${url}&ch=${channel}`;
			} else {
				// tslint:disable-next-line:no-parameter-reassignment
				url = `${url}?ch=${channel}`;
			}
		}
		window.location.href = url;
		// tslint:disable-next-line:no-magic-numbers
	}, 150);
}

/**
 * 浮点数相乘 - 规避JavaScript中浮点数计算误差问题
 * @param arg1 {int|double} 乘数
 * @param arg2 {int|double} 乘数
 */
export function multi(arg1: any, arg2: any): any {
	let m: number = 0;
	let s1: string = arg1.toString();
	let s2: string = arg2.toString();
	try {
		m += s1.split('.')[1].length;
	} catch (e) { }
	try {
		m += s2.split('.')[1].length;
	} catch (e) { }

	return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
}

/**
 * 检测dom元素是否完全在可视区域内
 * @param {HTMLElement} dom dom元素
 * @returns {boolean} dom元素是否可见
 */
export function isVisibleDom(dom: HTMLElement): any {
	const rect: any = dom.getBoundingClientRect();

	return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || window.document.documentElement.clientHeight) && rect.right <= (window.innerWidth || window.document.documentElement.clientWidth);
}

/**
 * 生成随机数
 */
export function randomUrl(): string {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
		.replace(/[xy]/g, (c): any => {
			// tslint:disable-next-line:no-magic-numbers
			let r: number = (Math.random() * 16) | 0;
			let v: number = c === 'x' ? r : (r & 0x3) | 0x8;

			return v.toString(16);
		})
		.toUpperCase();
}

// export function shareQla(id: any = ''): void {
// 		if (typeof _qla !== 'undefined') {
// 				_qla('event', {
// 						category: 'wechat_share',
// 						action: 'success',
// 						business_id: id
// 				});
// 		}
// }

// 正整数数字转中文,暂时只支持到三位数
// tslint:disable-next-line:completed-docs
export function number2Chinese(num: any): any {
	let dw: string[] = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
	if (num <= 0) {
		return dw[0];
	}
	if (isNaN(Number(num))) {
		return num || dw[0];
	}
	let maxCount: any = 0; // 多少位数
	if (num / 100 >= 1) {
		maxCount = 3;
	} else if (num / 10 >= 1) {
		maxCount = 2;
	} else if (num / 1 >= 1) {
		maxCount = 1;
	}
	let resultStr: string = '';
	for (let i: number = maxCount; i > 0; i--) {
		if (i === 3) {
			let hun: number = Math.floor(num / 100);
			resultStr += dw[hun] + '百';
		}
		if (i === 2) {
			let ten: number = Math.floor((num % 100) / 10);
			if (ten === 0) {
				resultStr += dw[ten];
			} else {
				resultStr += dw[ten] + '十';
			}
		}
		if (i === 1) {
			let sin: number = Math.floor(((num % 100) % 10) / 1);
			if (maxCount > 1 && sin === 0) {
				resultStr += '';
			} else {
				resultStr += dw[sin];
			}
		}
	}

	return resultStr;
}

/************************ 验证输入类型是否符合格式 - start ******************/
/**
 * @param {Object} validType-检验类型(text\money\name\password)
 * @param {Object} typeName-提示标题
 * @param {Object} inputVal-检验值
 * @param {Object} maxNum-最大值
 * @param {Object} minNum-最小值
 */

export type VALIDTYPE = 'text' | 'money' | 'name' | 'password' | 'wxAccount' | 'phoneNum';

export const validLegal: any = (validType: VALIDTYPE, typeName: string, inputValParam: string | number, maxNum?: number, minNum?: number, spec_tips?: string): any => {
	let inputVal: string = typeof inputValParam === 'string' ? inputValParam.trim() : Number(inputValParam).toString();
	let isPass: boolean = true;
	if (inputVal === '') {
		window.toast(typeName + '不能为空');

		return false;
	}
	switch (validType) {
		case 'text':
			isPass = checkText();
			break;
		case 'money':
			isPass = checkMoney();
			break;
		case 'name':
			isPass = checkName();
			break;
		case 'password':
			isPass = checkPassword();
			break;
		case 'wxAccount':
			isPass = checkWxAccount();
			break;
		case 'phoneNum':
			isPass = checkPhoneNum();
			break;
	}

	// tslint:disable-next-line:completed-docs
	function checkText(): boolean {
		if (maxNum && inputVal.length > maxNum) {
			window.toast(typeName + '不能超过' + maxNum.toString() + '个字');

			return false;
		} else {
			return true;
		}
	}

	// tslint:disable-next-line:completed-docs
	function checkMoney(): boolean {
		let tips: string = '';
		if (!/(^[0-9]*[\.]?[0-9]{0,2}$)/.test(inputVal)) {
			window.toast(typeName + '必须为非负数字,最多2位小数');

			return false;
		} else if (maxNum && Number(inputVal) > maxNum) {
			if (spec_tips && spec_tips !== '') {
				tips += '，' + spec_tips;
			}
			window.toast(typeName + '不能超过' + maxNum.toString() + '元' + tips);

			return false;
		} else if (minNum && Number(inputVal) < minNum) {
			if (spec_tips && spec_tips !== '') {
				tips += '，' + spec_tips;
			}
			window.toast(typeName + '不能小于' + minNum.toString() + '元' + tips);

			return false;
		} else {
			return true;
		}
	}

	// tslint:disable-next-line:completed-docs
	function checkName(): boolean {
		if (!/(^[a-zA-Z]+$)|(^[\u4e00-\u9fa5]+$)/.test(inputVal)) {
			window.toast('请输入真实姓名');

			return false;
		} else if (maxNum && inputVal.length > maxNum) {
			window.toast(typeName + '不能超过' + maxNum.toString() + '个字');

			return false;
		} else {
			return true;
		}
	}

	// tslint:disable-next-line:completed-docs
	function checkPassword(): boolean {
		if (!/^[0-9a-zA-Z]+$/.test(inputVal)) {
			window.toast(typeName + '只能是数字与字母组成');

			return false;
		} else if (maxNum && inputVal.length > maxNum) {
			window.toast(typeName + '最长为' + maxNum.toString() + '位');

			return false;
		} else {
			return true;
		}
	}

	// tslint:disable-next-line:completed-docs
	function checkWxAccount(): boolean {
		if (!/^[0-9a-zA-Z\-\_]{5,30}$/.test(inputVal)) {
			window.toast('微信号仅6~30个字母，数字，下划线或减号组成');

			return false;
		} else {
			return true;
		}
	}

	// tslint:disable-next-line:completed-docs
	function checkPhoneNum(): boolean {
		if (!/^1\d{10}$/.test(inputVal)) {
			window.toast('请输入正确的手机号');

			return false;
		} else {
			return true;
		}
	}

	return isPass;
};

/**
 *
 * 获取cookie
 * @param {any} name
 * @returns
 */
export function getCookie(name: string): string {
	if (document.cookie.length > 0) {
		let c_start: number = document.cookie.indexOf(name + '=');
		if (c_start !== -1) {
			c_start = c_start + name.length + 1;
			let c_end: number = document.cookie.indexOf(';', c_start);
			if (c_end === -1) {
				c_end = document.cookie.length;
			}

			return decodeURIComponent(document.cookie.substring(c_start, c_end));
		}
	}

	return '';
}

/**
 * 添加cookie
 *
 * @param {any} c_name
 * @param {any} value
 * @param {any} expiredays
 */
export const setCookie: any = (c_name: string, value: any, expiredays: number, path: string = '/', setZeroTime: boolean): any => {
	let exdate: Date = new Date();
	if (setZeroTime) {
		exdate.setHours(0, 0, 0, 0);
	}
	exdate.setDate(exdate.getDate() + expiredays);
	document.cookie = c_name + '=' + encodeURIComponent(value) + (expiredays === null ? '' : ';expires=' + exdate.toUTCString()) + ';path=' + path;
};

/**
 * 生成唯一的字符串
 * @return str{string}
 */
export function genUniqStr(): string {
	return Math.random().toString(36).substring(7) + Date.now().toString(16);
}

/**
 * getValidSize 根据屏幕分辨率 设置字体大小
 * @param size
 */
export function getValidSize(size: number): any {
	const width: number = document.documentElement.clientWidth;

	const scale: number = (width > 1125 ? 1125 : width) / 750;
	const validSize: number = size * scale;

	console.log('---:', validSize);

	return validSize;
}

/**
 * 获取页面TDK
 * @param props
 */
export function getHtmlTDK(
	htmlTDK: any
): {
	title: string;
	pageDescription?: string;
	pageKeyword?: string;
} {
	let { title = '千聊', pageDescription = '千聊，2亿人都在用的学习平台！个体机构讲师免费进驻～', pageKeyword = '' } = htmlTDK || {};

	return {
		title,
		pageDescription: pageDescription.replace(/_/g, ''),
		pageKeyword: pageKeyword.replace(/_/g, ','),
	};
}

/**
 * 本地存储获取方法，若过期则返回空
 * @type {[type]}
 */
export const getLocalStorage: any = (key: string): any => {
	let data: any;

	try {
		data = JSON.parse(localStorage.getItem(key));
	} catch (e) {
		console.error('get localStorage failed! ' + JSON.stringify(e));

		return null;
	}

	if (data && 'object' === typeof data && data._expires) {
		let nowDate: number = new Date().getTime();

		// 已过期
		if (+data._expires < nowDate) {
			try {
				localStorage.removeItem(key);
			} catch (e) {
				// Do something when catch error
				console.error('remove storage sync failed! ' + JSON.stringify(e));
			}

			console.info('缓存已过期！', key);

			return null;
		}

		return data.value;
	}

	return data;
};

/**
 * 本地存储方法，可设置过期时间
 *
 *	 setLocalStorage('test', 1, 10);
 *
 * @param  {String} key    存储的数据的key
 * @param  {Object/String} value 存储的内容
 * @param  {[type]} expires 过期时间（单位：秒(s)）
 * @return {[type]}        [description]
 */
export const setLocalStorage: any = (key: string, value: any, exp: number): void => {
	let nowDate: any = new Date();
	let data: any;
	let expires: any = exp;

	if (expires) {
		expires = Number(nowDate.getTime()) + +expires * 1000;

		data = {
			_expires: expires,
			value,
		};
	} else {
		data = value;
	}

	if (typeof data === 'object') {
		data = JSON.stringify(data);
	}

	try {
		localStorage.setItem(key, data);
	} catch (e) {
		console.error('set storage sync failed! ' + JSON.stringify(e));
	}
};

/**
 * htmlTransferGlobal
 */
export const htmlTransferGlobal: any = (sf: string): any => {
	let sfData: string = sf || '';
	sfData = sfData.replace(/&lt;/g, (m: any): any => '<');
	sfData = sfData.replace(/&gt;/g, (m: any): any => '>');
	sfData = sfData.replace(/(\&quot\;)/g, (m: any): any => '"');
	sfData = sfData.replace(/(\&\#39\;)/g, (m: any): any => "'");

	return sfData;
};

/**
 * 从iframe截取src值
 */
export const getVieoSrcFromIframe: any = (iframeStr: string): any => {
	let reg: RegExp = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;

	if (!iframeStr) {
		return '';
	}

	let matchs: any = iframeStr.match(reg);

	if (matchs && matchs.length > 1) {
		return matchs[1];
	}

	return '';
};

/**
 * isBeginning
 */
export const isBeginning: any = (startTime: number, nowTime: number): any => {
	const timeNow: number = Math.floor(new Date().getTime());
	const d: number = (Math.floor(startTime) - (nowTime || timeNow)) / 1000;
	const d_days: number = Math.floor(d / 86400);
	const d_hours: number = Math.floor(d / 3600);
	const d_minutes: number = Math.floor(d / 60);

	if (d_days > 0 || d_hours > 0 || d_minutes > 0) {
		return false;
	} else {
		return true;
	}
};

/**
 * 检查时间小于2位数
 */
export function checkTime(t: any): any {
	let i: any = t;
	if (i < 10) {
		i = `0${i}`;
	}

	return i;
}

/**
 * 获取课程跳转地址
 */
export function getJumpLink(type: string, id: string): string {
	switch (type) {
		case 'channel':
			return `/frontend/series/${id}.html`;
		case 'liveCamp':
		case 'topic':
			return `/frontend/course/${id}.html`;
		case 'live':
			return `/frontend/room/${id}.html`;
		default:
			return null;
	}
}

/**
 * 根据时间戳显示几天后开始的字符串
 * @param  {[type]} startTime [description]
 * @return {[type]}           [description]
 */
export function timeAfter(startTime: any, nowTime: any, endTime?: any): any {
	const timeNow: number = Math.floor(new Date().getTime());
	const d: number = (Math.floor(startTime) - (nowTime || timeNow)) / 1000;
	const d_days: number = Math.floor(d / 86400);
	const d_hours: number = Math.floor(d / 3600);
	const d_minutes: number = Math.floor(d / 60);

	if (d_days > 0 /*&& d_days < 15*/) {
		return `${d_days}天后`;
	} else if (d_days <= 0 && d_hours > 0) {
		return `${d_hours}小时后`;
	} else if (d_hours <= 0 && d_minutes > 0) {
		return `${d_minutes}分钟后`;
	} else if (endTime && (nowTime || timeNow) > endTime) {
		return '已结束';
	} else {
		return '进行中';
	}
}

/**
 * noShifterParseDangerHtml
 * @param c
 */
export function noShifterParseDangerHtml(c: any): any {
	let content: any = c || '';

	if (typeof document !== 'undefined') {
		let output: any;
		let elem: any = document.createElement('div');
		elem.innerHTML = content;
		output = elem.innerText || elem.textContent;

		return { __html: output };
	} else {
		content = content.replace(/\&amp;/g, (m: any): any => '&');

		return { __html: content };
	}
}

/**
 * 截取化图片某个尺寸，获取格式化后的链接
 * @param {string} url   链接
 * @param {string} formatStrQ   阿里云图片截取格式 "@64h_64w_1e_1c_2o"
 * @param {string} formatStrW   微信图片截取格式 "/64"
 *  图片截取大小格式化，获取链接 （兼容微信图片和阿里云图片）
 *
 *  例子：imgUrlFormat(url, "@64h_64w_1e_1c_2o", "/64");
 */
export function imgUrlFormat(url: string, formatStrQ: string = '@64h_64w_1e_1c_2o', formatStrW: any = '/64'): any {
	let newUrl: any = url;

	if (/(img\.qlchat\.com)/.test(newUrl)) {
		newUrl = url.replace(/@.*/, '') + formatStrQ;
	} else if (/(wx\.qlogo\.cn\/mmopen)/.test(newUrl)) {
		newUrl = url.replace(/(\/(0|132|64|96)$)/, formatStrW);
	}

	return newUrl;
}
